import $ from 'jquery';
import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'focus-visible';
import 'focus-within';
import 'intersection-observer';
import 'objectFitPolyfill';
import { withJS } from '@cookieshq/with-js/src/index';
import smoothscroll from 'smoothscroll-polyfill';
// import '@nathanvda/cocoon';

// window.vendorJquery = $;
window.jQuery = $;
window.$ = $;

//TODO: Split into only what we need from bootstrap
import 'bootstrap/dist/js/bootstrap';

// Enable Bootstrap's tooltip
$('[data-toggle="tooltip"]').tooltip();
$(document).on('turbolinks:render turbolinks:load', () => {
  $('[data-toggle="tooltip"]').tooltip();
  withJS();
  require('web-share-wrapper');
});

$(document).on('turbolinks:before-cache', () => {
  $('[data-toggle="tooltip"]').tooltip('dispose');
});

smoothscroll.polyfill();

document.addEventListener("turbolinks:load", () => {
  Rails.refreshCSRFTokens();
});

// The cookiebot banner traps focus within it, so
// overriding that method with a function to correct
// the order of the buttons
$(window).on('CookiebotOnDialogInit', () => {
  window.CookieConsentDialog.trapBannerFocus = function() {
    const $button = $('#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll');
    $button.prev().insertAfter($button);
    return;
  };
});

// Give access to Rails to the files in `application.js`
window.Rails = Rails;
Rails.start();
Turbolinks.start();
